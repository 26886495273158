<template>
    <div class="add_update_account">
        <jy-dialog type="form" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogWidth">
            <div>
                <el-form :model="diaform" ref="dialogForm" :rules="rules" :label-width="formLabelWidth">
                    <el-form-item label="登录账号:" prop="userNa">
                        <el-input v-model="diaform.userNa" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="用户名称:" prop="markNa">
                        <el-input v-model="diaform.markNa" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="登录密码:" prop="passwd" v-if="isShow">
                        <el-input placeholder="请输入密码" v-model="diaform.passwd" show-password></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码:" prop="cPasswd" v-if="isShow">
                        <el-input placeholder="请输入密码" v-model="diaform.cPasswd" show-password></el-input>
                    </el-form-item>
                    <el-form-item label="所属机构:" prop="orgNa">
                        <div @click="selectInstitutions">
                            <el-input placeholder="请选择" v-model="diaform.orgNa"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="所属角色:" prop="roleId">
                        <el-select v-model="diaform.roleId" placeholder="请选择">
                            <el-option v-for="item in statusList" :key="item.roleId" :label="item.roleNa" :value="item.roleId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="企业LOGO:" prop="image" :label-width="formLabelWidth">
                        <el-upload class="avatar-uploader" :action="uploadImgUrl" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :headers="uploadHeader">
                            <img v-if="diaform.image" :src="diaform.image" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="账号状态:" prop="active" :label-width="formLabelWidth">
                        <el-radio v-model="diaform.active" label="1">启用</el-radio>
                        <el-radio v-model="diaform.active" label="0">禁用</el-radio>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" url="/system/org/queryOrgTree" title="选择机构"></institutions-tree>
    </div>
</template>
<script>
import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
export default {
    data() {
        return {
            isShow: true,
            type: "",
            statusList: [],
            title: "",
            diaform: {
                userNa: "",
                markNa: "",
                passwd: "",
                cPasswd: "",
                orgNa: "",
                orgId: "",
                roleId: "",
                active: "",
                image: "",
            },
            rules: {
                userNa: [
                    {
                        required: true,
                        message: "请输入登录账号",
                        trigger: "blur",
                    },
                ],
                markNa: [
                    {
                        required: true,
                        message: "请输入用户名称",
                        trigger: "blur",
                    },
                ],
                passwd: [
                    {
                        required: true,
                        message: "请输入登录密码",
                        trigger: "blur",
                    },
                ],
                cPasswd: [
                    {
                        required: true,
                        message: "请输入确认密码",
                        trigger: "blur",
                    },
                ],
                orgNa: [
                    {
                        required: true,
                        message: "请选择所属机构",
                        trigger: "change",
                    },
                ],
                roleId: [
                    {
                        required: true,
                        message: "请选择所属角色",
                        trigger: "blur",
                    },
                ],
                image: [
                    {
                        required: true,
                        message: "请上传企业图片",
                        trigger: "blur",
                    },
                ],
                active: [
                    {
                        required: true,
                        message: "请选择账号状态",
                        trigger: "blur",
                    },
                ],
            },
            dialogVisible: false,
            formLabelWidth: "100px",
            uploadImgUrl: "/IntelligentArrangeSystem/sys/user/uploadLogo",
            uploadHeader: {},
        };
    },
    created() {
        this.setUploadHeader();
    },
    components: {
        institutionsTree,
    },
    watch: {
        type: function (val) {
            let t = "";
            switch (val) {
                case "add":
                    t = "新增账号";
                    this.isShow = true;
                    break;
                case "update":
                    t = "修改账号";
                    this.isShow = false;
                    break;
                default:
                    t = "新增账号";
            }
            this.title = t;
        },
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm("dialogForm");
                this.statusList = [];
                this.diaform.orgId = "";
            }
        },
    },
    methods: {
        setUploadHeader() {
            let header = {
                token: this.$util.getStor("token"),
            };
            this.uploadHeader = header;
        },
        getAllList() {
            let url = "/sys/role/queryRoleByOrgId";
            this.$http.post(url, this.diaform.orgId, { isRequestParam: false }).then(({ detail }) => {
                this.statusList = detail;
            });
        },
        init(option, type) {
            this.type = type ? type : "add";
            this.dialogVisible = true;
            if (option) {
                this.$nextTick(() => {
                    this.diaform = JSON.parse(JSON.stringify(option));
                    this.getAllList();
                });
            }
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        cancel() {
            this.dialogVisible = false;
            console.log("cancel");
        },
        // 图片上传
        beforeAvatarUpload(file) {
            console.log(file.type);
            const isJPG =
                file.type === "image/jpeg" || file.type === "image/png";

            if (!isJPG) {
                this.$message.error("图片只能是JPG或PNG格式");
            }
            return isJPG;
        },
        handleAvatarSuccess({ detail }, file) {
            // this.diaform.image = URL.createObjectURL(file.raw)
            // this.$set(this.diaform, 'image', detail)
            this.diaform.image = detail;

            console.log(detail)
            console.log(file)
        },
        // 选择机构树
        selectInstitutions() {
            this.$refs.institutions.init();
        },
        // 上级机构查询
        addInstitutions(data) {
            // console.log(data)
            this.diaform.orgId = data.orgId;
            this.diaform.orgNa = data.orgNa;

            this.getAllList();
            this.diaform.roleId = "";
        },
        save() {
            this.$refs.dialogForm.validate((valid) => {
                if (valid) {
                    let url = "/sys/user/";
                    if (this.type == "add") {
                        url = url + "add";
                    } else {
                        url = url + "update";
                    }
                    let option = this.diaform;
                    Object.keys(option).forEach(item=>{
                        if(typeof option[item] == 'object'){
                            delete option[item]
                        }
                    })
                    this.$http.post(url, option,{ isRequestParam: false }).then((res) => {
                        this.$message({
                            type: "success",
                            message: res.msg,
                        });
                        this.dialogVisible = false;
                        this.$emit("updateList");
                    });
                    // console.log(this.diaform.orgId)
                } else {
                    return false;
                }
            });
        },
    },
};
</script>